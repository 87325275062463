var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "job-item" }, [
    _c("div", { staticClass: "job-item-header" }, [
      _c("div", { staticClass: "job-title" }, [
        _c(
          "a",
          {
            staticClass: "job-title-link text-ellipsis",
            attrs: {
              target: "_blank",
              title: _vm.job.name,
              href: `/#/Job/${_vm.job.id}`,
            },
          },
          [
            _vm._v(
              "\n                " + _vm._s(_vm.job.name) + "\n            "
            ),
          ]
        ),
        _c(
          "span",
          {
            staticClass: "job-location text-ellipsis",
            attrs: { title: _vm.job.location },
          },
          [
            _vm._v(
              "\n                [" +
                _vm._s(_vm.job.location) +
                "]\n            "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "job-detail-tags" },
          [
            _vm._l(_vm.job.activityTags, function (item) {
              return _c(
                "el-tooltip",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        item.id !== "StandstillJob" &&
                        item.id != "FirstOrderActivityJob",
                      expression:
                        "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                    },
                  ],
                  key: item.id,
                  attrs: {
                    effect: "light",
                    placement: "right",
                    disabled: !item.description,
                    "popper-class": "job-tag-description",
                  },
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: { innerHTML: _vm._s(item.description) },
                    slot: "content",
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "job-detail-tag",
                      class: `job-tag-${_vm.jobActivityTag[item.id].theme}`,
                      attrs: { title: item.name },
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              )
            }),
            _vm.job.hasRecord
              ? _c("span", {
                  staticClass: "job-record-icon",
                  attrs: { title: "该职位有职位讲解" },
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _c("div", { staticClass: "job-item-body" }, [
      _c("div", { staticClass: "job-intro" }, [
        _c("div", { staticClass: "job-info" }, [
          _c("span", { staticClass: "job-info-item" }, [
            _vm._v(_vm._s(_vm.job.salary == "0K-0K" ? "面议" : _vm.job.salary)),
          ]),
          _c("span", { staticClass: "job-info-item" }, [
            _vm._v(_vm._s(_vm.job.yearOfExperience)),
          ]),
          _c("span", { staticClass: "job-info-item" }, [
            _vm._v(_vm._s(_vm.job.degree)),
          ]),
          _c("span", { staticClass: "job-info-item" }, [
            _vm._v("需 " + _vm._s(_vm.job.recruitingCount) + " 人"),
          ]),
        ]),
        _c("div", { staticClass: "job-promise" }, [
          _vm._v("\n                佣金\n                "),
          _c(
            "span",
            {
              staticClass: "text-orange job-commission-value",
              attrs: {
                title:
                  _vm.job.commissionValueText &&
                  String(_vm.job.commissionValueText).indexOf("0K-0K") > -1
                    ? "按比例"
                    : _vm.job.commissionValueText,
              },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(
                    _vm.job.commissionValueText &&
                      String(_vm.job.commissionValueText).indexOf("0K-0K") > -1
                      ? "按比例"
                      : _vm.job.commissionValueText
                  ) +
                  "\n                "
              ),
            ]
          ),
          _vm._v("\n                保证期\n                "),
          _c("span", { staticClass: "text-orange job-guarantee-period" }, [
            _vm._v(_vm._s(_vm.job.guaranteePeriod)),
          ]),
          _vm._v("\n                个月\n            "),
        ]),
        _vm.job.isCooperativeJob &&
        _vm.userInfo.headhuntingFirmType > 0 &&
        ((_vm.userInfo.headhuntingFirmType > 2 &&
          _vm.job.headhuntingFirmCommissionValue > 0) ||
          (_vm.userInfo.headhuntingFirmType < 3 &&
            _vm.job.otherFirmCommissionValue > 0))
          ? _c(
              "div",
              { staticClass: "job-firm-commission" },
              [
                _vm._v("\n                分佣比例\n                "),
                _c("span", { staticClass: "text-grey" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.userInfo.headhuntingFirmType > 2
                          ? _vm.job.headhuntingFirmCommissionValue + "%"
                          : _vm.job.otherFirmCommissionValue + "%"
                      ) +
                      "\n                "
                  ),
                ]),
                _vm.userInfo.headhuntingFirmType < 3
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          "popper-class": "commission-rate-text",
                          effect: "light",
                          content: "简历提供者，分成比例需要和平台运营确认。",
                          placement: "right",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-icon" },
                          [
                            _c("font-icon", {
                              staticClass: "default",
                              attrs: { href: "#icon-talent_qd" },
                            }),
                            _c("font-icon", {
                              staticClass: "hover",
                              attrs: { href: "#icon-talent_ql" },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "job-status" }, [
        _c(
          "span",
          {
            staticClass: "job-customer text-ellipsis",
            attrs: { title: _vm.job.customerName },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.job.customerName) +
                "\n            "
            ),
          ]
        ),
        _c("span", { staticClass: "job-date" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._f("createDateFilter")(_vm.job.created)) +
              " 发布 / " +
              _vm._s(_vm._f("dateFilter")(_vm.job.updated)) +
              " 更新\n            "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "job-item-body-right" },
        [
          _c("avatar", {
            staticClass: "user-avatar",
            attrs: {
              size: "sm",
              src: _vm.job.avatarUrl,
              userId: _vm.job.userId,
              enableCard: true,
              enableLink: true,
              shareData: _vm.shareData,
            },
          }),
          !_vm.userInfo.isEmployer
            ? [
                _c("div", { staticClass: "job-number" }, [
                  _c("span", { attrs: { title: "推荐" } }, [
                    _vm._v(
                      "\n                        推 " +
                        _vm._s(_vm.job.recommendCount) +
                        "\n                    "
                    ),
                  ]),
                  _c("span", { attrs: { title: "面试" } }, [
                    _vm._v(
                      "\n                        面 " +
                        _vm._s(_vm.job.interviewCount) +
                        "\n                    "
                    ),
                  ]),
                  _c("span", { attrs: { title: "offer" } }, [
                    _vm._v(
                      "\n                        offer " +
                        _vm._s(_vm.job.offerCount) +
                        "\n                    "
                    ),
                  ]),
                ]),
                _c(
                  "el-dropdown",
                  {
                    class: {
                      hidden: !(
                        _vm.userInfo.isAdministrator || _vm.job.isBinding
                      ),
                    },
                    attrs: { placement: "bottom" },
                    on: { command: _vm.handleJobMoreOperate },
                  },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.moreOperateLoading,
                          expression: "moreOperateLoading",
                        },
                      ],
                      staticClass: "el-dropdown-link el-icon-more",
                    }),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "job-more-opr-dropdown",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "removeJob" } },
                          [_vm._v("从项目中删除该职位")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }