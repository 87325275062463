<template>
    <div class="job-item">
        <div class="job-item-header">
            <div class="job-title">
                <!-- <a
                    target="_blank"
                    :title="job.name"
                    class="job-title-link text-ellipsis"
                    :href="`/Headhunting/MyCompany.html#/Job/${job.id}`">
                    {{job.name}}
                </a> -->
                <a
                    target="_blank"
                    :title="job.name"
                    class="job-title-link text-ellipsis"
                    :href="`/#/Job/${job.id}`">
                    {{job.name}}
                </a>
                <span class="job-location text-ellipsis" :title="job.location">
                    [{{job.location}}]
                </span>
                <div class="job-detail-tags">
                    <el-tooltip
                        v-for="item in job.activityTags"
                        :key="item.id"
                        effect="light"
                        placement="right"
                        :disabled="!item.description"
                        v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                        popper-class="job-tag-description">
                        <div slot="content" v-html="item.description"></div>
                        <span class="job-detail-tag" :class="`job-tag-${jobActivityTag[item.id].theme}`" :title="item.name">{{item.name}}</span>
                    </el-tooltip>
                    <span class="job-record-icon" title="该职位有职位讲解" v-if="job.hasRecord"></span>
                </div>
            </div>
        </div>
        <div class="job-item-body">
            <div class="job-intro">
                <div class="job-info">
                    <span class="job-info-item">{{job.salary == '0K-0K' ? '面议' : job.salary}}</span>
                    <span class="job-info-item">{{job.yearOfExperience}}</span>
                    <span class="job-info-item">{{job.degree}}</span>
                    <span class="job-info-item">需 {{job.recruitingCount}} 人</span>
                </div>
                <div class="job-promise">
                    佣金
                    <!-- <span class="text-orange job-commission-value" :title="job.commissionValue && String(job.commissionValue).indexOf('0K-0K') > -1 ? '按比例' : job.commissionValue">
                        {{job.commissionValue && String(job.commissionValue).indexOf('0K-0K') > -1 ? '按比例' : job.commissionValue}}
                    </span> -->
                    <span class="text-orange job-commission-value" :title="job.commissionValueText && String(job.commissionValueText).indexOf('0K-0K') > -1 ? '按比例' : job.commissionValueText">
                        {{job.commissionValueText && String(job.commissionValueText).indexOf('0K-0K') > -1 ? '按比例' : job.commissionValueText}}
                    </span>
                    保证期
                    <span class="text-orange job-guarantee-period">{{job.guaranteePeriod}}</span>
                    个月
                </div>
                <div
                    class="job-firm-commission"
                    v-if="job.isCooperativeJob && userInfo.headhuntingFirmType > 0 && ((userInfo.headhuntingFirmType > 2 && job.headhuntingFirmCommissionValue > 0) || (userInfo.headhuntingFirmType < 3 && job.otherFirmCommissionValue > 0))">
                    分佣比例
                    <span class="text-grey">
                        {{userInfo.headhuntingFirmType > 2 ? job.headhuntingFirmCommissionValue + '%' : job.otherFirmCommissionValue + '%'}}
                    </span>
                    <el-tooltip
                        popper-class="commission-rate-text"
                        effect="light"
                        content="简历提供者，分成比例需要和平台运营确认。"
                        placement="right"
                        v-if="userInfo.headhuntingFirmType < 3">
                        <div class="tip-icon">
                            <font-icon class="default" href="#icon-talent_qd"></font-icon>
                            <font-icon class="hover" href="#icon-talent_ql"></font-icon>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="job-status">
                <!-- <a 
                    :target="isMyFirm ? '_blank' : ''"
                    class="job-customer text-ellipsis"
                    :class="{'no-link': !isMyFirm}"
                    :href="isMyFirm ? `/Headhunting/MyCompany.html#/Customer/${job.customerId}` : 'javascript:void(0)'"
                    :title="job.customerName">
                    {{job.customerName}}
                </a> -->
                <span 
                    class="job-customer text-ellipsis"
                    :title="job.customerName">
                    {{job.customerName}}
                </span>
                <span class="job-date">
                    {{job.created | createDateFilter}} 发布 / {{job.updated | dateFilter}} 更新
                </span>
            </div>
            <div class="job-item-body-right">
                <!-- <avatar
                    class="user-avatar"
                    size="sm"
                    :src="job.avatarUrl | formatAvatar"
                    :userId="job.userId"
                    :enableCard="true"
                    :enableLink="true"
                    :shareData="shareData"
                ></avatar> -->
                <avatar
                    class="user-avatar"
                    size="sm"
                    :src="job.avatarUrl"
                    :userId="job.userId"
                    :enableCard="true"
                    :enableLink="true"
                    :shareData="shareData"
                ></avatar>
                <template v-if="!userInfo.isEmployer">
                    <div class="job-number">
                        <span title="推荐">
                            推 {{job.recommendCount}}
                        </span>
                        <span title="面试">
                            面 {{job.interviewCount}}
                        </span>
                        <span title="offer">
                            offer {{job.offerCount}}
                        </span>
                    </div>
                    <!-- v-if="userInfo.isAdministrator || job.isBoundOwner" -->
                    <el-dropdown
                        placement="bottom"
                        :class="{'hidden': !(userInfo.isAdministrator || job.isBinding)}"
                        @command="handleJobMoreOperate">
                        <span
                            class="el-dropdown-link el-icon-more"
                            v-loading="moreOperateLoading"
                        ></span>
                        <el-dropdown-menu
                            class="job-more-opr-dropdown"
                            slot="dropdown">
                            <el-dropdown-item 
                                command="removeJob" 
                                >从项目中删除该职位</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </div>
            
        </div>
    </div>
</template>

<script>
import jobActivityTag from '#/js/config/jobActivityTag.json';
import jobStatus from '#/js/config/jobStatus.json';
import Avatar from '#/component/common/avatar.vue';
import {job as jobUrl} from '#/js/config/api.json';

import projectDetailService from '#/js/service/projectDetailService.js';
import moment from 'moment';
export default {
    components: {
        Avatar
    },
    props: {
        projectItemId: {
            type: String,
            default: ""
        },
        job: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isMyFirm: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        shareData() {
            let jobInfo = this.job;
            return {
                isPublic: jobInfo.isCooperativeJob,
                jobId: jobInfo.id,
                jobName: jobInfo.name,
                firmShortName: jobInfo.firmShortName,
                location: jobInfo.location,
                yearOfExperience: jobInfo.yearOfExperience,
                degree: jobInfo.degree,
                salary: jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary,
                commissionValue: jobInfo.commissionValue && String(jobInfo.commissionValue).indexOf('0K-0K') > -1 ? '按比例' : jobInfo.commissionValue,
                creatorId: jobInfo.ownerId,
                creatorName: `${jobInfo.realName}@${jobInfo.nickname}`,
                creatorAvatar: jobInfo.avatarUrl,
                isInnerJob: !jobInfo.isCooperativeJob
            };
        },
        projectId() {
            return this.$route.params.projectId;
        }
    },
    data() {
        return {
            jobActivityTag: jobActivityTag,
            jobStatus: jobStatus,
            moreOperateLoading: false
        };
    },
    filters: {
        createDateFilter(val) {
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(val).format('YYYY-MM-DD');
            if(today === dateDay) {
                return '今天';
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        },
        dateFilter(val) {
            let now = new Date(),
                date = val;
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(date).format('YYYY-MM-DD'),
                nowTime = new Date().getTime(),
                dateTime = new Date(date).getTime(),
                oneHourTime = 60 * 60 * 1000,
                hour = now.getHours(),
                dateHours = new Date(date).getHours();
            if(today === dateDay) {
                if (dateTime > nowTime - oneHourTime * 4 && dateTime < nowTime - oneHourTime) {
                    return (hour - dateHours) + '小时前';
                } else if (dateHours == hour || (dateTime > nowTime && dateTime < nowTime + oneHourTime) || (dateTime < nowTime && dateTime > nowTime - oneHourTime)) {  // 目标时间与当前时间
                    let minutesDiff = Math.floor((dateTime - nowTime) / 60000);
                    if(minutesDiff == 0 || minutesDiff == 1) {
                        return '刚刚';
                    } else if(minutesDiff < 0) {
                        return (minutesDiff * -1) + '分钟前';
                    }
                } else {
                    return '今天';
                }
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        },
        formatAvatar(url) {
            if(NODE_ENV && (NODE_ENV == 'dev' || NODE_ENV == 'rc')) {
                url = url.replace('www.rc.liebide.com', 'portalapi.rc.liebide.com');
            }
            return url;
        }
    },
    methods: {
        handleJobMoreOperate(command) {
            // 埋点
            // lbdTrack.track('myCompanyJob',
            //     {
            //         isbyHotSearch: location.hash.slice(1) !== '' && location.hash.slice(1) !== 'null' ? true : false,
            //         type: command,
            //         id: this.job.jobId
            //     }
            // );
            switch(command) {
                case 'removeJob':
                    this.removeJob();
                    break;
                // case 'grabOrder':
                //     this.grabOrder();
                //     break;
                // case 'recommend':
                //     window.open(`/Headhunting/MyCompany.html#/Job/${this.job.jobId}?action=clickOperate`);
                //     break;
                // case 'changeJobStatus':
                //     this.$emit('changeJobStatus', this.job);
                //     break;
            }
        },
        // 从项目中移除职位
        removeJob() {
            // console.log(this.projectId);
            // console.log(this.projectItemId);
            this.$confirm(`是否确定从项目中删除该职位？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const params = {
                    projectId: this.projectId ? this.projectId : this.projectItemId,
                    JobId: this.job.id,
                };
                
                this.moreOperateLoading = true;
                const loading = this.$loading({
                    lock: true,
                    text: '页面加载中~',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                projectDetailService.DeleteJobBinding(params).then(res => {
                    console.log(res)
                    shortTips(`从项目中删除职位成功！`)
                    this.$emit('changeJobStatus', this.job);
                }).finally(() => {
                    loading.close();
                    this.moreOperateLoading = false;
                });

            }).catch(() => {
                shortTips('已取消删除操作!');
            });
            
        },
    }
};
</script>

<style lang="scss" scope>
    .hidden{
        visibility: hidden;
    }
    .job-item {
        background-color: #fff;
        padding: 20px;
        border-top: 1px solid #eee;
        &:first-child{
            border-top: 1px solid transparent;
        }
        .text-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &:hover {
            background-color: #f6f6f6;
        }
        &-header {
            height: 40px;
            line-height: 40px;
            color: #333;
            font-size: 16px;
            .job-title {
                display: flex;
                height: 40px;
                &-link {
                    max-width: 350px;
                    margin-right: 15px;
                }
                .job-location {
                    max-width: 280px;
                    margin-right: 10px;
                }
                .job-detail-tags {
                    margin-bottom: 0;
                    user-select: none;
                    white-space: nowrap;
                    .job-detail-tag {
                        line-height: 16px;
                        text-align: center;
                        font-size: 12px;
                        border: 1px solid #b9b9b9;
                        padding: 0 6px;
                        border-radius: 10px;
                        color: #b9b9b9;
                        margin-right: 7px;
                        &.job-tag-danger{
                            border-color: #ff493c;
                            color: #ff493c;
                        }
                        &.job-tag-primary{
                            border-color: #38bc9d;
                            color: #38bc9d;
                        }
                        &.job-tag-info{
                            border-color: #39f;
                            color: #39f;
                        }
                        &.job-tag-warning{
                            border-color: #ff7200;
                            color: #ff7200;
                        }
                    }
                    .job-record-icon {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background: url("~@src/assets/images/jobList/job-record-icon.png") no-repeat 0 0;
                        background-size: contain;
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }
        &-body {
            display: flex;
            justify-content: space-between;
            color: #666;
            line-height: 20px;
            .job-intro {
                width: 280px;
                .job-info {
                    margin-bottom: 10px;
                    white-space: nowrap;
                    .job-info-item {
                        border-right: 1px solid #d3d3d3;
                        padding-right: 3px;
                        margin-right: 5px;
                        &:nth-last-of-type(1) {
                            border-right: none;
                        }
                    }
                }
                .job-promise {
                    margin-bottom: 10px;
                    color: #b9b9b9;
                    white-space: nowrap;
                    .job-commission-value {
                        // margin: 0 10px;
                        margin: 0 5px;
                        display: inline-block;
                        max-width: 142px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: bottom;
                    }
                    .job-guarantee-period {
                        // margin-left: 10px;
                        margin-left: 5px;
                    }
                    .text-orange {
                        color: #f39800;
                    }
                }
                .job-firm-commission {
                    color: #b9b9b9;
                    .text-grey {
                        color: #666;
                    }
                }

                .tip-icon {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    margin-left: 5px;
                    .font-icon {
                        width: 16px;
                        height: 16px;
                        margin-top: -3px;
                        opacity: 1;
                    }
                    .hover {
                        display: none;
                    }
                    &:hover {
                        .default {
                            display: none;
                        }
                        .hover {
                            display: inline-block;
                        }
                    }
                }
            }
            .job-status {
                // width: 240px;
                width: 235px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .job-customer {
                    min-width: 90px;
                    margin-bottom: 10px;
                    max-width: 190px;
                    &.no-link {
                        cursor: default;
                        &:hover {
                            color: #333;
                        }
                    }
                }
                .job-date {
                    color: #999;
                }
            }
            &-right {
                display: flex;
            }
            .user-avatar {
                margin-right: 10px;
                margin-top: -6px;
                width: 30px;
                height: 30px;
                .avatar-sm {
                    width: 28px;
                    height: 28px;
                }
            }
            .job-number {
                span {
                    display: inline-block;
                    min-width: 40px;
                    margin-right: 5px;
                }
            }
            .el-dropdown {
                height: 30px;
                .el-dropdown-link {
                    color: #ccc;
                    display: inline-block;
                    width: 60px;
                    text-align: center;
                    line-height: 20px;
                    font-size: 26px;
                }
            }
        }
    }
</style>

<style lang="scss">
.job-tag-description.el-tooltip__popper.is-light {
    margin-left: 7px;
    color: #666;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .popper__arrow {
        border: 6px solid transparent;
        border-left: none;
        border-right-color: rgba(0, 0, 0, 0.2);
        &::after {
            border-right-color: #fff;
        }
    }
}
.commission-rate-text.el-tooltip__popper.is-light {
    margin-left: 8px;
    margin-top: 40px;
    .popper__arrow {
        display: none;
    }
}
.job-more-opr-dropdown.el-dropdown-menu {
    margin-top: 0;
    padding: 0;
    .el-dropdown-menu__item {
        text-align: center;
    }
}
</style>
